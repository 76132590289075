// @WIP - Needs cleaning up and refactoring into smaller components

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import BennyPhoneImage from '@public/Benny_phone.png';
import BennyRightImage from '@public/Benny_Pointing_Left.png';

import { filters } from '@mocks/components/Organisms/ChoosePackage/data.mock.js';

import {
  fromMarkdown,
  getAddressTypeFromSla,
  getFormattedPrice,
  getFriendlyProductTitle,
  getMonthlyPriceCalculated,
  getOriginalPrice,
  getProductBenefitTag,
  getProductPromotion,
  chooseMonthlyPriceByPostcode,
  chooseBenefitTagByPoscode,
} from '@lib/utils';

import useBasketContext from '@hooks/useBasketContext';
import usePostcodeContext from '@hooks/usePostcodeContext';
import usePromotionsContext from '@hooks/usePromotionsContext';

import { Button, Icon, Toggle } from '@components/Atoms';
import { ButtonType } from '@components/Atoms/Button/types';

import { CardPackageT } from './types';

import StyledCardPackage from './styled/StyledCardPackage';
import StyledChoosePackage from './styled/StyledChoosePackage';
import StyledProducts from './styled/StyledProducts';
import StyledCardPackageSelectedButton from './styled/StyledCardPackageSelectedButton';
import StyledCardPackageLabel from './styled/StyledCardPackageLabel';
import StyledCardPackageTitle from './styled/StyledCardPackageTitle';
import StyledCardPackageDescription from './styled/StyledCardPackageDescription';
import StyledCardPackageList from './styled/StyledCardPackageList';
import StyledCardPackageBody from './styled/StyledCardPackageBody';
import StyledCardPackagePrice from './styled/StyledCardPackagePrice';
import StyledFiltersWrap from './styled/StyledFiltersWrap';
import StyledCardPackageOriginalPrice from './styled/StyledCardPackageOriginalPrice';
import StyledCardPackageAddonFirstCharge from './styled/StyledCardPackageAddonFirstCharge';
import StyledCardPackageHeading from './styled/StyledCardPackageHeading';
import StyledDisclaimerText from './styled/StyledDisclaimerText';
import StyledCardPackageFooter from './styled/StyledCardPackageFooter';
import StyledCardPackageSpeed from './styled/StyledCardPackageSpeed';
import StyledCardPackageProgress from './styled/StyledCardPackageProgress';
import StyledBottomLink from '@components/Organisms/ChoosePackage/styled/StyledBottomLink';
import StyledHelpCard from '@components/Organisms/ChoosePackage/styled/StyledHelpCard';
import StyledCardPackageAnimation from '@components/Organisms/ChoosePackage/styled/StyledCardPackageAnimation';

import CheckIcon from '@public/icons/Check-3.svg';
import PodsImage from '@public/pods.jpg';
import ArrowCircleUpImage from '@public/arrow-circle-up.png';
import ArrowCircleDownImage from '@public/arrow-circle-down.png';
import GigaBikeImage from '@public/Benny_GIGABIKE.png';
import MegaBikeImage from '@public/Benny_MEGABIKE.png';
import SuperBikeImage from '@public/Benny_SUPERBIKE.png';

import { Switch } from '@components/Atoms/Switch';
import { CtaT } from '@components/Molecules/Cta/types';
import { Cta, SectionHeading } from '@components/Molecules';
import useGAEvent from '@hooks/useGAEvent';
import { checkIsHomePhone, getHomePhoneForProduct } from '@lib/utils/homePhone';
import ChoosePackageSubheading from './styled/ChoosePackageSubheading';
import StyledBeforeImage from './styled/StyledChoosePackageBeforeImage';
import StyledRightImage from './styled/StyledChoosePackageRightImage';
import useCheckoutContext from '@hooks/useCheckoutContext';

const PackageTypeMap: {
  [key: string]: string;
} = {
  HWRK: 'home-worker',
  RES: 'residential',
  BUS: 'business',
  MSM: 'multi-dwelling',
};

// Split by newline only if it has newlines, otherwise do it by comma.
const getUspDelimiter = (usp_items) => (usp_items?.includes('\n') ? '\n' : ',');

const Filters: FC<any> = ({ filters, selectedFilter, setFilter }) => {
  const toggles = filters.map(({ key, value, description }) => ({
    value: key,
    name: value,
    description,
  }));
  return <Toggle toggles={toggles} selected={selectedFilter} setSelected={setFilter} />;
};

const CardPackageSelectedButton: FC<any> = ({ children, ...props }) => {
  return (
    <StyledCardPackageSelectedButton buttonType={ButtonType.PRIMARY} fullWidth {...props}>
      <span>{children}</span>
      <Icon xs icon={CheckIcon} />
    </StyledCardPackageSelectedButton>
  );
};

export const CardPackage: FC<any> = ({
  id_product,
  i,
  name,
  description,
  monthly_price,
  display_name,
  display_description,
  usp_items,
  speed,
  speed_down,
  speed_up,
  category,
  display_benefit_tag,
  display_preorder_benefit_tag,
  isAddon,
  display_original_price,
  first_charge,
  exclude_vat_price,
  disabled,
  isSmartWifi,
  hasHomePhone,
  homePhoneProduct,
  addressType,
  preorder_montly_price,
  promotions,
  address_type,
  isInView = false,
  months,
}: CardPackageT) => {
  const router = useRouter();
  const [isHover, setIsHover] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const {
    setBasketItem,
    isSelectedItem,
    addons,
    setAddons,
    setPromoCode,
    getBasketItemProduct,
    setBundle,
  } = useBasketContext();
  const { postcodeItem, isCurrentPostcodeEligible, isCurrentPostcodePreorder } =
    usePostcodeContext();
  const { setCheckoutSkipTo } = useCheckoutContext();

  const basketItemProduct = getBasketItemProduct();
  const isSelected = id_product === basketItemProduct?.id_product;

  // @todo Make this better...
  const uspItems = usp_items?.split(getUspDelimiter(usp_items));
  if (hasHomePhone && uspItems) {
    uspItems.push('Unlimited calls to UK landlines and mobiles');
  }

  const promo = useMemo(() => {
    return getProductPromotion({ id_product, coverage: postcodeItem?.coverage, promotions });
  }, [id_product, postcodeItem?.coverage, promotions]);

  const deductablePromo = Array.isArray(promo)
    ? promo.find(
        ({ promotion_type }) => promotion_type === 'RECURAMTOFF' || promotion_type === 'RECUR%OFF',
      )
    : promo;

  const monthlyPrice = useMemo(() => {
    return chooseMonthlyPriceByPostcode({
      postcodeItem,
      isCurrentPostcodePreorder,
      preorder_montly_price,
      monthly_price,
    });
  }, [postcodeItem, isCurrentPostcodePreorder, preorder_montly_price, monthly_price]);

  const benefitTag = useMemo(() => {
    return chooseBenefitTagByPoscode({
      postcodeItem,
      isCurrentPostcodePreorder,
      display_preorder_benefit_tag,
      display_benefit_tag,
    });
  }, [postcodeItem, isCurrentPostcodePreorder, display_preorder_benefit_tag, display_benefit_tag]);

  const monthlyPriceCalculated = useMemo(() => {
    return getMonthlyPriceCalculated({
      monthly_price: monthlyPrice,
      exclude_vat_price,
      hasHomePhone,
      homePhoneProduct,
      addressType,
      promo: deductablePromo,
    });
  }, [
    monthlyPrice,
    addressType,
    exclude_vat_price,
    hasHomePhone,
    homePhoneProduct,
    deductablePromo,
  ]);

  const originalPriceCalculated = useMemo(() => {
    return getOriginalPrice({
      display_original_price,
      exclude_vat_price,
      hasHomePhone,
      homePhoneProduct,
      addressType,
      monthly_price: monthlyPrice,
      promo: deductablePromo,
    });
  }, [
    addressType,
    display_original_price,
    exclude_vat_price,
    hasHomePhone,
    homePhoneProduct,
    monthlyPrice,
    deductablePromo,
  ]);

  const gaEvent = useGAEvent();

  const selectBasketItem = useCallback(() => {
    if (isAddon) {
      setAddons((prev = []) => {
        const prevAddon = prev?.find((addonId) => addonId === id_product);
        if (prevAddon) {
          return addons?.filter((addonId) => addonId !== id_product);
        }
        return [...prev, id_product];
      });
    } else {
      if (hasHomePhone) {
        setAddons([homePhoneProduct?.id_product]);
      } else {
        setAddons([]);
      }

      setBasketItem(
        id_product,
        originalPriceCalculated,
        monthlyPriceCalculated,
        hasHomePhone ? homePhoneProduct : null,
      );

      setBundle(false);
      setCheckoutSkipTo('');

      //@promo-debug
      // auto promotions are added to the basket when the user selects a package
      const productAutoPromos = promotions?.filter(
        (promo) => promo?.auto && promo?.products?.includes(id_product),
      );

      setPromoCode(productAutoPromos);

      const scrollToCTA = document.querySelector('#sticky-checkout-footer__products');
      if (scrollToCTA) {
        scrollToCTA.scrollIntoView({
          block: 'end',
        });
      }
    }
  }, [
    isAddon,
    setAddons,
    id_product,
    addons,
    hasHomePhone,
    setBasketItem,
    display_name,
    originalPriceCalculated,
    monthlyPriceCalculated,
    isCurrentPostcodeEligible,
    homePhoneProduct?.id_product,
    router,
    gaEvent,
    getBasketItemProduct,
  ]);

  display_benefit_tag = getProductBenefitTag({
    display_benefit_tag: benefitTag,
    promo: deductablePromo,
  });

  let motorBikeImage = SuperBikeImage;

  if (parseInt(speed) >= 900) {
    motorBikeImage = GigaBikeImage;
  } else if (parseInt(speed) >= 400 && parseInt(speed) < 900) {
    motorBikeImage = MegaBikeImage;
  } else {
    motorBikeImage = SuperBikeImage;
  }

  const callGaViewItemEvent = useCallback(() => {
    gaEvent.send({
      action: 'view_item',
      parameters: {
        selected_product: {
          id_product,
          monthlyPriceCalculated,
        },
      },
    });
  }, [gaEvent, id_product, monthlyPriceCalculated]);

  return (
    <StyledCardPackage
      $isSelected={isSelected}
      $isDisabled={disabled}
      data-id={id_product}
      onClick={() => selectBasketItem()}
    >
      <StyledCardPackageAnimation run={isHover} speed={i ? 0.8 / i : 1}>
        <Image src={motorBikeImage} alt="Speed" />
      </StyledCardPackageAnimation>
      {display_benefit_tag && (
        <StyledCardPackageLabel>{display_benefit_tag}</StyledCardPackageLabel>
      )}

      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="card-package-body-container"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onTouchStart={() => setIsHover(false)}
        onTouchEnd={() => setIsHover(false)}
      >
        <StyledCardPackageBody>
          <div hidden={readMore}>
            <StyledCardPackageProgress isHover={isHover}>
              <div role="progressbar">
                <div />
              </div>
            </StyledCardPackageProgress>

            <StyledCardPackageHeading $isAddon={isAddon}>
              {isAddon && (
                <div>
                  <Image src={PodsImage} alt="Smart WiFi pods" />
                </div>
              )}
              <div>
                <StyledCardPackageTitle>
                  {getFriendlyProductTitle({ name, display_name, category })} {speed}
                </StyledCardPackageTitle>
                {monthlyPriceCalculated && (
                  <StyledCardPackagePrice>
                    {originalPriceCalculated &&
                      originalPriceCalculated > monthlyPriceCalculated && (
                        <StyledCardPackageOriginalPrice>
                          £{originalPriceCalculated}
                        </StyledCardPackageOriginalPrice>
                      )}
                    <div>
                      <span>{`£${monthlyPriceCalculated}`}</span>
                      <span>/month</span>
                    </div>
                  </StyledCardPackagePrice>
                )}
                {isAddon && (
                  <StyledCardPackageAddonFirstCharge>
                    {first_charge && parseFloat(first_charge) > 0
                      ? `£${getFormattedPrice(first_charge)} admin fee`
                      : 'No admin fee'}
                  </StyledCardPackageAddonFirstCharge>
                )}
              </div>
            </StyledCardPackageHeading>

            <StyledCardPackageSpeed
              isSymmetrical={!!speed_down && !!speed_up && speed_down === speed_up}
            >
              {(!speed_down || speed_down !== speed_up) && (
                <div className="speed-container">
                  <Image
                    src={ArrowCircleDownImage}
                    width={17}
                    height={17}
                    alt="Download"
                    title="Download"
                  />
                  <div>
                    <span>{speed_down}</span> <span>Mbps*</span>
                  </div>
                  <div>Average download</div>
                </div>
              )}

              {(!speed_up || speed_down !== speed_up) && (
                <div className="speed-container">
                  <Image
                    src={ArrowCircleUpImage}
                    width={17}
                    height={17}
                    alt="Upload"
                    title="Upload"
                  />
                  <div>
                    <span>{speed_up}</span> <span>Mbps*</span>
                  </div>
                  <div>Average upload</div>
                </div>
              )}

              {!!speed_down && !!speed_up && speed_down === speed_up && (
                <>
                  <div className="speed-container">
                    <div>
                      <Image
                        src={ArrowCircleDownImage}
                        width={17}
                        height={17}
                        alt="Download"
                        title="Download"
                      />
                      <Image
                        src={ArrowCircleUpImage}
                        width={17}
                        height={17}
                        alt="Upload"
                        title="Upload"
                      />
                    </div>
                    <div>
                      <span>{speed_down}</span> <span>Mbps*</span>
                    </div>
                    <div>Average download/upload</div>
                  </div>

                  <div className="symmetrical-speed-container">
                    <p>SYMMETRICAL UPLOAD/DOWNLOAD*</p>
                  </div>
                </>
              )}
            </StyledCardPackageSpeed>
          </div>

          <div hidden={!readMore}>
            <StyledCardPackageTitle>
              {getFriendlyProductTitle({ name, display_name, category })} {speed}
            </StyledCardPackageTitle>
            <StyledCardPackageDescription $isAddon={isAddon}>
              {display_description || description?.replace(/\n/gim, '. ')}
            </StyledCardPackageDescription>
            {!isAddon && uspItems && (
              <StyledCardPackageList>
                {uspItems?.map((uspItem) => (
                  <li key={uspItem}>{uspItem}</li>
                ))}
              </StyledCardPackageList>
            )}
          </div>

          <StyledCardPackageFooter>
            <>
              {isSelected ? (
                <CardPackageSelectedButton
                  onClick={async (event) => {
                    event.preventDefault();
                    event.stopPropagation(); // Stop events bubbling up and double-clicking
                  }}
                >
                  Selected
                </CardPackageSelectedButton>
              ) : (
                <Button buttonType={ButtonType.SECONDARY} fullWidth as="div">
                  {isAddon
                    ? `Add ${isSmartWifi ? 'Smart WiFi' : name}`
                    : `${
                        addressType !== 'MSM'
                          ? `Select ${getFriendlyProductTitle({
                              name,
                              display_name,
                              category,
                            })} ${speed}`
                          : 'Confirm'
                      }`}
                </Button>
              )}
            </>

            {readMore ? (
              <Button
                buttonType={ButtonType.HYPERLINK}
                fullWidth
                onClick={(e) => {
                  setReadMore(false);
                  e.stopPropagation();
                }}
              >
                ← Back
              </Button>
            ) : (
              <Button
                buttonType={ButtonType.HYPERLINK}
                fullWidth
                onClick={(e) => {
                  setReadMore(true);
                  callGaViewItemEvent();
                  e.stopPropagation();
                }}
              >
                Read more
              </Button>
            )}
          </StyledCardPackageFooter>
        </StyledCardPackageBody>
      </div>
    </StyledCardPackage>
  );
};

// @todo Make this more robust, this should not be filtering by name!!
function checkIsSmartWifi(product) {
  return product.name.match(/Smart WiFi/gim);
}

// @todo Perhaps check whether the filters contain any packages before showing the button.
// function preflightFilters({ products }) {}

interface ExcludeProduct {
  id: number;
  productID: string;
  productName: string;
}

export const ChoosePackage: FC<any> = ({
  heading,
  subHeading,
  subHeadingLink,
  addressType = 'RES',
  displaySmartWifi = false,
  products = null,
  bottomLinkText,
  bottomLinkHref,
  bottomCardTitle,
  bottomCardBody,
  bottomCardButton,
  displayBottomCard,
  purchasePage = false,
  showBennyAbovePackages = false,
  exclusion_list: exclusionList,
}: {
  heading: string;
  subHeading?: string;
  subHeadingLink?: string;
  addressType?: string;
  displaySmartWifi?: boolean;
  products?: any[] | null; // Typescript goes mad if I use the proper types here.
  bottomLinkText: string;
  bottomLinkHref: string;
  bottomCardTitle: string;
  bottomCardBody: string;
  bottomCardButton: CtaT;
  displayBottomCard: boolean;
  purchasePage: boolean;
  showBennyAbovePackages: boolean;
  exclusion_list: ExcludeProduct[];
}) => {
  const router = useRouter();
  const [filterPackageType, setFilterPackageType] = useState('BBO');
  const [filterMonths, setFilterMonths] = useState('24');
  useEffect(() => {
    const handleRouteChange = async (url: URL) => {
      setFilterMonths('24');
    };
    router?.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router?.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router?.events]);
  const product = router?.query?.product?.toString();
  const [isComponentInView, setIsComponentInView] = useState(false);
  const productRef = useRef<HTMLElement | null>(null);

  const { promotions } = usePromotionsContext();

  const monthFilters = filters.months.filter((filter) => {
    if (addressType === 'HWRK' && filter.key === '0') {
      return false;
    }
    if (filterPackageType === 'BBANDP' && filter.key === '0') {
      return false;
    }
    return true;
  });

  useEffect(() => {
    function scrollListener() {
      if (productRef.current) {
        const { top, bottom } = productRef?.current?.getBoundingClientRect();

        setIsComponentInView(top >= 0 || bottom <= window.innerHeight);
      }
    }

    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  // Hide 30 days roling for broadband + phone
  useEffect(() => {
    if (filterPackageType === 'BBANDP' && filterMonths === '0') {
      setFilterMonths('24');
    }
  }, [filterPackageType, filterMonths]);

  const productsToExclude = (exclusionList || []).reduce((acc, { productID }) => {
    acc[productID] = !product || product !== productID;
    return acc;
  }, {});

  const filteredProducts = products
    ?.filter(({ sla, address_type, id_product }) => {
      if (productsToExclude[id_product]) {
        return false;
      }
      if (product) {
        return id_product === product;
      }
      if (sla && address_type !== 'MSM') {
        return addressType === getAddressTypeFromSla(sla);
      }
      return addressType === address_type;
    })
    ?.filter(({ months }) => addressType === 'MSM' || filterMonths === months?.toString());

  const filteredProductsDisplay = filteredProducts
    ?.filter((product) => !checkIsSmartWifi(product))
    ?.filter((product) => !checkIsHomePhone(product))
    ?.sort(({ monthly_price: a }, { monthly_price: b }) => a - b)
    ?.sort(({ speed: a }, { speed: b }) => a - b);

  const gaEvent = useGAEvent();

  // This component does not get un-mounted and then re-mounted whenever the page
  // changes, presumably because the [slug] route is technically the same component
  // and it's sharing the same mounted instance of ChoosePackage (I think). So we need
  // to listen for the addressType changing and actively reset the Business version's
  // filters since they're hidden for the user and we can get into a stuck state.
  useEffect(() => {
    // Track when the user views the package list and what type

    if (isComponentInView) {
      gaEvent.send({
        action: 'view_item_list',
        parameters: {
          package_types: PackageTypeMap[addressType],
          viewed_items: filteredProductsDisplay,
          promotions: promotions,
        },
      });
    }
  }, [addressType, isComponentInView]);

  if (!products) {
    return <div>Products could not be loaded...</div>;
  }

  return (
    <StyledChoosePackage
      className="choose-package"
      purchasePage={purchasePage}
      id="product-selector"
    >
      {showBennyAbovePackages && (
        <StyledBeforeImage>
          <Image
            src={BennyPhoneImage}
            alt="Hey! Broadbands Benny holding a phone"
            layout="fill"
            objectFit="contain"
          />
        </StyledBeforeImage>
      )}
      <StyledRightImage>
        <Image
          src={BennyRightImage}
          alt="Hey! Broadbands Benny pointing at the packages"
          layout="fill"
          objectFit="contain"
        />
      </StyledRightImage>
      <SectionHeading heading={heading} />

      {subHeading &&
        (subHeadingLink ? (
          <Link href={subHeadingLink} passHref>
            <ChoosePackageSubheading href={subHeadingLink} as="a">
              {subHeading}
            </ChoosePackageSubheading>
          </Link>
        ) : (
          <ChoosePackageSubheading as="p">{subHeading}</ChoosePackageSubheading>
        ))}

      {addressType !== 'BUS' && addressType !== 'MSM' && (
        <StyledFiltersWrap>
          <Switch
            checked={filterPackageType === 'BBANDP'}
            onChange={(checked) => {
              checked ? setFilterPackageType('BBANDP') : setFilterPackageType('BBO');
            }}
            label={
              filterPackageType === 'BBANDP' ? 'Phoneline included' : 'Want to include phoneline?'
            }
          />

          <Filters
            filters={monthFilters}
            selectedFilter={filterMonths}
            setFilter={setFilterMonths}
          />
        </StyledFiltersWrap>
      )}

      <StyledProducts ref={productRef}>
        {filteredProductsDisplay?.length ? (
          <>
            {filteredProductsDisplay.map((product, i) => (
              <CardPackage
                i={i}
                key={product.id_product}
                hasHomePhone={filterPackageType === 'BBANDP'}
                homePhoneProduct={getHomePhoneForProduct({ products, product })}
                addressType={addressType}
                promotions={promotions}
                {...product}
                products={filteredProductsDisplay}
                isInView={isComponentInView}
              />
            ))}
          </>
        ) : (
          <>
            <div style={{ padding: '1rem', minHeight: '30rem' }}>No products</div>
          </>
        )}
      </StyledProducts>

      <StyledDisclaimerText>
        {filterPackageType === 'BBANDP' ? (
          <>
            *Average speed when plugged into router. Our home phone plan allows you to call
            emergency services on 999 and 112 for free but this won&apos;t work if there&apos;s a
            power or network failure.
          </>
        ) : (
          <>
            *Average speeds, achievable by 50% of users, are 900 Mbps on a 1 Gbps connection. 400
            Mbps on a 400 Mbps connection, 150 Mbps on a 150 Mbps connection. 900 Mbps speed is not
            achievable by a single device over Wi-Fi, for this speed a wired connection is required.
          </>
        )}
      </StyledDisclaimerText>

      {bottomLinkText && (
        <div className="mx-4 mt-4 mb-12 text-center">
          <Link passHref href={bottomLinkHref}>
            <StyledBottomLink>{bottomLinkText}</StyledBottomLink>
          </Link>
        </div>
      )}

      {displayBottomCard && (
        <StyledHelpCard>
          <div>
            <h4>{bottomCardTitle}</h4>
            {fromMarkdown({ text: bottomCardBody, isHeading: false })}
          </div>

          <Cta {...bottomCardButton} />
        </StyledHelpCard>
      )}
    </StyledChoosePackage>
  );
};
