import React, { useCallback, useEffect, useState } from 'react';
import { Postcode } from '@components/Organisms/Postcode/Postcode';
import { CtaT } from '@components/Molecules/Cta/types';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import usePostcodeContext from '@hooks/usePostcodeContext';
import { useRouter } from 'next/router';
import { Form } from './components/Form';
import Phone from '@public/icons/Phone.svg';
import { isExistingCustomerCheck, postcodeSubmitCallback } from '@lib/utils/uprn';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { UprnStatus } from '@lib/types';

const STYLED = {
  container: styled.section`
    background-color: var(--color-secondary-light-blue);
  `,
  wrapper: styled.div`
    padding-top: 2rem;
    padding-bottom: 2rem;
    & > * {
      &:first-child {
        background: var(--color-blue);
        padding: 2rem 1rem;
        max-width: 515px;
      }
    }
    @media ${breakpoints.desktop} {
      padding-top: 4rem;
      padding-bottom: 4rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 2rem;
      max-width: 60rem;

      & > * {
        flex: 1;

        &:first-child {
          background: var(--color-blue);
          padding: 4rem 3rem;
        }
      }
    }
    @media ${breakpoints.desktopLarge} {
      max-width: 70rem;
    }
  `,
  heading: styled.h2`
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--color-primary);
    line-height: 1;
  `,
  card: styled.div`
    padding-top: 3rem;

    @media ${breakpoints.desktop} {
      padding-top: 4rem;
      display: flex;
      justify-content: center;
    }
    div {
      border-radius: 2rem;
      @media ${breakpoints.desktop} {
        background: var(--color-white);
        padding: 2.5rem;
        box-shadow: 0px 5px 9px 5px rgba(124, 146, 213, 0.2);
      }
    }

    h4 {
      font-size: 1.5rem;
      color: var(--color-primary);
    }

    p {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 0.25rem;
      color: black;
      line-height: 1.3;

      @media ${breakpoints.desktop} {
        max-width: 16rem;
      }
    }

    a {
      font-size: 1.5rem;
      font-weight: 400;

      color: black;

      &::before {
        content: '';
        position: relative;
        top: 0.33rem;
        margin-right: 0.75rem;
        background-image: url(${Phone.src});
        background-size: 1.75rem;
        background-repeat: no-repeat;
        background-position: center;
        width: 1.75rem;
        height: 1.75rem;
        display: inline-block;
      }
      span {
        color: var(--color-primary);
        text-decoration: underline;
      }
    }
  `,
  postcode: styled.div`
    & > p {
      font-family: var(--font-geomanist);
      color: black;
      font-weight: 500;
      font-size: 1.25rem;
    }
    & > small {
      display: block;
      margin-top: -0.25rem;
      margin-bottom: -0.5rem;
      font-family: var(--font-geomanist);
      color: black;
      font-weight: 300;
      font-size: 1rem;
    }
    [class*='StyledHeroMain__FakeInput'] {
      max-width: 100%;
      margin: 0;
    }
  `,
  address: styled.div`
    & > p {
      font-family: var(--font-geomanist);
      color: black;
      font-weight: 500;
      font-size: 1.25rem;
    }

    & > div {
      background: rgba(255, 255, 255, 0.5);
      padding: 0.5rem;
      margin-top: 0.25rem;
      margin-bottom: 2rem;

      @media ${breakpoints.tablet} {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
      }
      p {
        color: black;
      }
    }

    button {
      color: var(--color-primary);
      font-weight: 600;
      font-family: var(--font-geomanist);
      text-decoration: underline;
    }
  `,
};

interface PropsI {
  display: boolean;
  heading: string;
  contactCard: {
    heading: string;
    text: string;
    cta: CtaT;
  };
  bundleVariant2024Sept: string;
}

export const ModernForm = (props: PropsI) => {
  const router = useRouter();

  const {
    isCurrentPostcodeEligible,
    postcodeItem,
    clearPostcode,
    setPostcodeItem,
    setHasConfirmedAddress,
  } = usePostcodeContext();

  const handlePostcodeSubmit = useCallback(
    (data) => {
      if (isExistingCustomerCheck(data.coverage, data.oss_type)) {
        return router.push('/error/existing-customer');
      }
      postcodeSubmitCallback({
        data,
        ineligibleCallback: () => {
          setPostcodeItem(data);
          return router.push('/service-unavailable');
        },
        successCallback: () => {
          setPostcodeItem(data);
          setHasConfirmedAddress(true);
        },
      });
    },
    [router, setHasConfirmedAddress, setPostcodeItem, clearPostcode],
  );

  const { display, heading, contactCard } = props;

  if (!display || props.bundleVariant2024Sept !== 'b') return null;

  return (
    <STYLED.container id="bundle-form" className="modern-form">
      <STYLED.wrapper className="slice__inner">
        <div>
          <header>
            <STYLED.heading>{heading}</STYLED.heading>
          </header>
          <STYLED.postcode>
            {!isCurrentPostcodeEligible && (
              <>
                <p
                  dangerouslySetInnerHTML={{
                    __html: "What's your postcode",
                  }}
                ></p>
                <small
                  dangerouslySetInnerHTML={{
                    __html: "We just need to check if we're in your area",
                  }}
                ></small>
                <Postcode
                  showTitle={false}
                  inline={true}
                  onSubmit={handlePostcodeSubmit}
                  placeholder="Enter your postcode to find out!"
                  shouldRedirect={false}
                  focusOnLoad={false}
                />
              </>
            )}
            {isCurrentPostcodeEligible && (
              <>
                <STYLED.address>
                  <p>Your address</p>
                  <div>
                    <p>{postcodeItem?.address}</p>
                    <button onClick={clearPostcode}>Change</button>
                  </div>
                </STYLED.address>
              </>
            )}
            <GoogleReCaptchaProvider
              reCaptchaKey={
                process.env.RECAPTCHA_SITE_KEY || '6LeEfhwoAAAAAGFBvsuHhiQtZLX-Br6hBC4Vq8FZ'
              }
              scriptProps={{
                async: false,
                defer: false,
                appendTo: 'head',
                nonce: undefined,
              }}
              container={{
                element: 'recaptcha-container',
                parameters: {},
              }}
            >
              <Form />
            </GoogleReCaptchaProvider>
          </STYLED.postcode>
        </div>
        {!!contactCard && (
          <STYLED.card>
            <div>
              <h4>{contactCard.heading}</h4>
              <p>{contactCard.text}</p>
              {!!contactCard?.cta?.link && (
                <a
                  href={contactCard.cta.link}
                  dangerouslySetInnerHTML={{
                    __html: contactCard.cta.label,
                  }}
                ></a>
              )}
            </div>
          </STYLED.card>
        )}
      </STYLED.wrapper>
    </STYLED.container>
  );
};
