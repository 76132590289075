import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { fromMarkdown, renderImage } from '@lib/utils';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';
import { Format, ImageDataT, Layout } from '@lib/types';
import { CtaT } from '@components/Molecules/Cta/types';
import { Cta } from '@components/Molecules';

interface ReferAFriendT {
  display: boolean;
  preTitle: string;
  title: string;
  body: string;
  cta: CtaT;
  image: ImageDataT;
}

const STYLED = {
  wrapper: styled.section`
    position: relative;
    overflow: hidden;
    background-color: var(--color-blue);
  `,
  grid: styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${rem(40)};

    @media ${breakpoints.tablet} {
      grid-template-columns: 1fr 1fr;
    }
  `,
  image: styled.div`
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      object-fit: contain;
    }
  `,
  content: styled.div`
    max-width: ${rem(550)};

    p.pre-title {
      font-size: 2rem;
      font-weight: 500;
      color: var(--color-black);
      margin-bottom: 0;
      font-family: var(--font-geomanist);

      @media ${breakpoints.smallDesktop} {
        font-size: 2.5rem;
      }
    }

    h2 {
      position: relative;
      font-style: italic;
      text-transform: uppercase;
      background: -webkit-linear-gradient(
        135deg,
        hsla(357, 91%, 51%, 1) 0%,
        hsla(240, 100%, 50%, 1) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 3rem;
      line-height: 1;
      font-weight: 700;

      display: flex;
      flex-direction: row;
      gap: 0.5rem;

      @media ${breakpoints.smallDesktop} {
        font-size: 3rem;
      }

      @media ${breakpoints.desktop} {
        font-size: 4rem;
      }

      span {
        text-transform: lowercase;
        display: contents;

        @media ${breakpoints.desktop} {
          // font-size: ${rem(100)};
        }
      }
    }
  `,
};

export const ReferAFriend: FC<ReferAFriendT> = ({ display, preTitle, title, body, cta, image }) => {
  if (!display) {
    return null;
  }

  return (
    <STYLED.wrapper className="slice">
      <div className="flex-col slice__inner">
        <STYLED.grid>
          {image && (
            <STYLED.image>
              {renderImage({
                alternativeText: image.alternativeText,
                formats: image.formats,
                format: Format.LARGE,
              })}
            </STYLED.image>
          )}
          <STYLED.content>
            {preTitle && (
              <p
                className="pre-title"
                dangerouslySetInnerHTML={{
                  __html: preTitle,
                }}
              />
            )}
            {title && (
              <h2
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            )}
            {body && fromMarkdown({ text: body, isHeading: false })}
            <Cta {...cta} />
          </STYLED.content>
        </STYLED.grid>
      </div>
    </STYLED.wrapper>
  );
};
