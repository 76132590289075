import React, { FC, useState, useEffect } from 'react';
import { StyledDescription, StyledHeroMain, StyledTitle } from './styled';
import { TrustBox } from '@components/Atoms';
import { Props } from './types';
import usePostcodeContext from '@hooks/usePostcodeContext';
import { Format } from '@lib/types';
import { fromMarkdown, renderImage } from '@lib/utils';
import { mockTrustBoxData } from '@components/Atoms/TrustBox/__mockData__';
import { PostcodeSearch } from '@components/Molecules/PostcodeSearch';
import { useRouter } from 'next/router';
import { CountdownTimer } from '@components/Atoms/CountdownTimer';
import replacePlaceholderWithTags from '@lib/utils/replacePlaceholderWithTags';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';
import { StyledSubTitle } from './styled/StyledSubTitle';
import { mapFieldColorToVar } from '@lib/utils/mapFieldColorToVar';
import { StyledPreTitle } from './styled/StyledTitle';
import { Cta } from '@components/Molecules/Cta';

const StyledDesktopImage = styled.div<{
  imagePosition?: string;
  imageScaleMode?: string;
}>`
  display: none;
  background-image: url('/winter-background.png'); // TODO: remove after winter campaign
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;

  @media ${breakpoints.tablet} {
    display: block;

    span {
      width: 100% !important;
    }

    img {
      object-fit: cover;
      object-position: bottom center;
    }
  }

  @media ${breakpoints.desktop} {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 40%;

    span {
      height: 100% !important;
      width: 100% !important;
      z-index: 1;
    }

    img {
      mix-blend-mode: multiply;
      object-fit: ${({ imageScaleMode }) =>
        imageScaleMode ? imageScaleMode.replace('_', '-') : 'contain'};
      object-position: ${({ imagePosition }) =>
        imagePosition ? imagePosition.replace('_', ' ') : 'center center'};
    }
  }

  @media ${breakpoints.desktopLarge} {
    width: 50%;
  }
`;

const StyledMobileImage = styled.div`
  display: block;
  background-color: var(--colors-background);

  span {
    display: block !important;
  }
  img {
    object-fit: contain;
    object-position: top right;
  }

  @media ${breakpoints.tablet} {
    display: none;
  }
`;

const StyledOffer = styled.div`
  p {
    font-family: var(--font-geomanist);
    font-weight: 500;
    color: black;
    font-size: calc(1rem + 0.75vw);
    margin-top: 1rem;

    @media ${breakpoints.desktop} {
      margin-top: 0.5rem;
    }
  }
  span {
    line-height: 0.66;
    background: linear-gradient(120deg, #f4111b 30%, #0402fd);
    color: white;
    padding: 0.25rem 0.75rem 0.125rem;
    border-radius: 2rem;
    margin-left: 0.5rem;
  }
`;

const StyledSmallText = styled.div`
  margin-top: 0;
  margin-bottom: 2rem;
  @media ${breakpoints.desktop} {
    margin-top: 0;
  }
  p {
    font-family: var(--font-geomanist);
    font-size: ${rem(14)};
    color: black;
    font-weight: 300;
  }
`;

const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  @media ${breakpoints.desktop} {
    align-items: flex-start;
    flex-direction: row;
    gap: 4rem;

    & > * {
      flex: 1;
    }
  }
`;

const StyledCTA = styled.div`
  margin-bottom: 2rem;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media ${breakpoints.desktop} {
    margin-bottom: 0;
    justify-content: flex-start;
  }
`;

export const HeroMain: FC<Props> = ({ data }) => {
  const {
    preHeading,
    headline,
    mobileImage,
    featureImage,
    description,
    smallText,
    offer,
    subHeadline,
    callToAction,
    defaultAddressType,
    postcodeLabel,
    AddHeadingGradient = false,
    AddCountdownTimer = false,
    TimerEnd,
    paddingTop,
    paddingBottom,
    imageScaleMode,
    imagePosition,
    subtitleColor,
    descriptionColor,
    timerBackgroundColor,
    cta,
  } = data || {};

  let { displayPostCodeChecker = true } = data;

  if (displayPostCodeChecker === null) displayPostCodeChecker = true;

  const { setAddressType } = usePostcodeContext() || {};

  // Countdown Timer Variables
  const time = `${TimerEnd}`;

  const hasTimerEnded = new Date() > new Date(TimerEnd || '');
  const [hasCountdownTimer, setHasCountdownTimer] = useState(AddCountdownTimer && !hasTimerEnded);

  const router = useRouter();

  const isHome = router && router.asPath === '/';

  useEffect(() => {
    setAddressType(defaultAddressType);
  }, [defaultAddressType, setAddressType]);

  return (
    <StyledHeroMain
      className="relative flex flex-col"
      desktopImageSrc={''}
      hasCountdownTimer={hasCountdownTimer}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <div className="xl:container xl:mx-auto hero-content">
        <StyledFlexContainer>
          <div className="hero-content__text">
            {!!preHeading && (
              <StyledPreTitle dangerouslySetInnerHTML={{ __html: preHeading }}></StyledPreTitle>
            )}
            {headline && (
              <>
                <StyledTitle
                  addHeadingGradient={AddHeadingGradient}
                  isHome={isHome}
                  dangerouslySetInnerHTML={{
                    __html: replacePlaceholderWithTags(headline, {
                      tag: 'strong',
                      attributes: `
                        class="hero-content__title__text"
                      `,
                    }),
                  }}
                ></StyledTitle>
              </>
            )}

            {subHeadline && (
              <StyledSubTitle
                color={mapFieldColorToVar(subtitleColor)}
                dangerouslySetInnerHTML={{
                  __html: `<span>${subHeadline}</span>`,
                }}
              ></StyledSubTitle>
            )}
            {offer && <StyledOffer>{fromMarkdown({ text: offer, isHeading: false })}</StyledOffer>}
            {description && (
              <StyledDescription color={mapFieldColorToVar(descriptionColor)}>
                {fromMarkdown({ text: description, isHeading: false })}
              </StyledDescription>
            )}
            {hasCountdownTimer && (
              <CountdownTimer
                time={time}
                onTimerEnd={() => setHasCountdownTimer(false)}
                bg={timerBackgroundColor}
              />
            )}
            {smallText && (
              <StyledSmallText>
                {fromMarkdown({ text: smallText, isHeading: false })}
              </StyledSmallText>
            )}

            {displayPostCodeChecker && (
              <p className="hero-content__postcode_label">{postcodeLabel}</p>
            )}

            <div className="hidden lg:block lg:mb-8">
              {displayPostCodeChecker && callToAction && <PostcodeSearch isStandalone={false} />}

              <TrustBox data={mockTrustBoxData} className="mt-12 mb-8 hero-content__trust-box" />

              {cta && (
                <StyledCTA className="hero-content__buttons">
                  <Cta
                    label={cta.label}
                    link={cta.link}
                    display={cta.display}
                    buttonType={cta.buttonType}
                    event={cta.event}
                  />
                </StyledCTA>
              )}
            </div>
          </div>

          <StyledDesktopImage imagePosition={imagePosition} imageScaleMode={imageScaleMode}>
            {featureImage &&
              renderImage({
                className: 'lg:h-full',
                alternativeText: featureImage.alternativeText,
                formats: featureImage.formats,
                format: Format.LARGE,
              })}
          </StyledDesktopImage>

          <StyledMobileImage>
            {mobileImage &&
              renderImage({
                className: '',
                alternativeText: mobileImage.alternativeText,
                formats: mobileImage.formats,
                format: Format.LARGE,
              })}
          </StyledMobileImage>
        </StyledFlexContainer>

        <div className="w-full px-4 lg:hidden">
          {displayPostCodeChecker && callToAction && <PostcodeSearch isStandalone={false} />}

          <TrustBox data={mockTrustBoxData} className="mb-8 hero-content__trust-box" />

          {cta && (
            <StyledCTA className="hero-content__buttons">
              <Cta
                label={cta.label}
                link={cta.link}
                display={cta.display}
                buttonType={cta.buttonType}
                event={cta.event}
              />
            </StyledCTA>
          )}
        </div>
      </div>
    </StyledHeroMain>
  );
};

export default HeroMain;
