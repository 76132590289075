//@promo-debug
// function used in a variety of places to check if product and promotion are compatible
// Also checks if the address is valid for the promotion
export const getProductPromotion = ({ id_product, coverage, promotions, filterAuto = true }) => {
  const promos = promotions.filter((promo: any) => {
    if (filterAuto && !promo.auto) return false;

    if (
      promo.uprn_status === 'PASSSERV' &&
      coverage === 'CUSTOMER' &&
      promo.products?.includes(id_product)
    ) {
      return true;
    }

    if (promo.uprn_status !== null && !!coverage && !promo.uprn_status.includes(coverage)) {
      return false;
    }

    return promo.products?.includes(id_product);
  });

  if (promos.length === 1) {
    return promos[0];
  }

  return promos;
};

export const validPromoCode = ({ promotion, coverage }) => {
  if (!promotion || !coverage) {
    return false;
  }

  const { uprn_status } = promotion;

  if (uprn_status === 'PASSSERV' && coverage === 'CUSTOMER') {
    return true;
  }

  if (uprn_status && !uprn_status.includes(coverage)) {
    return false;
  }

  return true;
};
