import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
export const StyledMediaBlock = styled.div<{
  showWaves: boolean;
  imagePositionDesktop: string;
  imageWidthDesktop: number;
  imagePositionMobile: string;
  imageWidthMobile: number;
}>`
  display: block;
  position: static;
  background: ${({ showWaves }) =>
    showWaves ? 'linear-gradient(0deg, #e2ccde 0%, #bdb9f0 50%, #e2ccde 100%)' : 'none'};
  max-width: 100%;

  &.background--blue {
    background: var(--color-blue);
  }

  h1.heading--blue {
    color: var(--color-primary);
  }

  .media-block {
    &__image-svg {
      height: ${rem(160)};
      position: relative;
      @media ${breakpoints.tablet} {
        height: ${rem(340)};
      }
      @media ${breakpoints.desktopLarge} {
        height: ${rem(500)};
      }
      @media ${breakpoints.desktopXL} {
        height: ${rem(600)};
      }
    }
    &__image_container {
      display: flex;
      justify-content: ${({ imagePositionMobile }) =>
        imagePositionMobile ? imagePositionMobile : 'center'};
      @media ${breakpoints.tablet} {
        justify-content: ${({ imagePositionDesktop }) =>
          imagePositionDesktop ? imagePositionDesktop : 'center'};
      }
    }
    &__image {
      padding-top: 30px;
      width: ${({ imageWidthMobile }) => (imageWidthMobile ? `${imageWidthMobile}%` : '40%')};
      @media ${breakpoints.tablet} {
        width: ${({ imageWidthDesktop }) => (imageWidthDesktop ? `${imageWidthDesktop}%` : '40%')};
      }
    }
    &__buttons {
      > :first-child {
        margin-bottom: ${rem(20)};
      }
      @media ${breakpoints.tablet} {
        display: flex;
        justify-content: center;
        > :first-child {
          margin-bottom: 0;
          margin-right: ${rem(20)};
        }
      }
    }
  }
  .card-package-body-container {
    position: relative;
    background: #fff;
    padding: 2.1875rem 2.5rem 0.625rem;
    margin: 5px;
    user-select: none;
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  .card-package-body {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 100%;
    color: #565661;
  }
  .card-package-button {
    transition: all 0.2s ease 0s;
    font-size: 1.1rem;
    position: relative;
    font-family: 'Geomanist';
    font-weight: 500;
    cursor: pointer;
    line-height: 48px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-around;
    border-radius: 6px;
    border: none;
    transform-style: preserve-3d;
    background-color: #f7f7ff;
    color: #2c2cde;
    width: 100%;
  }
  .card-package-button:before {
        content: "";
    position: absolute;
    inset: 10px;
    filter: blur(14px);
    transform: translate3d(0px, 12px, -1px);
    border-radius: inherit;
    pointer-events: none;
    background: linear-gradient(99deg, rgb(244, 17, 27) 0%, rgb(4, 2, 253) 100%) 0% 0% no-repeat padding-box padding-box transparent;
    width: 95%;
  }
  .card-package-progress {
    position: relative;
    width: 100%;
    padding: 1.5rem 0px;
  }
  .card-package-progress-bar {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px inset;
    width: 100%;
    height: 24px;
    border-radius: 12px;
    padding: 3px;
  }
  .card-package-progress-bar-fill {
    width: 100%;
    height: 100%;
    transition: width 0.3s ease 0s;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px inset;
    border-radius: 12px;
    background: linear-gradient(270deg, rgb(244, 17, 27) 0%, rgb(4, 2, 253) 100%) 0% 0% no-repeat
      padding-box padding-box transparent;
  }
  .card-package-heading {
    display: flex;
  }
  .card-package-heading-col {
    display: flex;
    flex-direction: column;
  }
  .card-package-heading-title {
    margin: 0px 0px 1rem;
    line-height: 1.3;
    color: #0000ff;
    font-size: 1.8rem;
    font-weight: 900;
    font-family: 'Geomanist';
  }
  .card-package-price {
    display: flex;
    align-items: flex-end;
  }
  .card-package-price-was {
    font-size: 1.125rem;
    margin-right: 0.3rem;
    text-decoration: line-through;
    line-height: 2.5;
    color: #565661;
  }
  .card-package-price-now {
    font-size: 3rem;
    color: #000;
    font-family: 'Geomanist';
    font-weight: bold;
    line-height: 1;
    margin-right: 0.25rem;
  }
  .card-package-price-period {
    font-size: 1.2rem;
  }
  .card-package-speed {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 1.5rem 0px;
  }
  .card-package-speed-value {
    font-size: 1.8rem;
    font-weight: bold;
    color: #000;
  }
  .card-package-speed-unit {
    color #000;
    font-size: 0.813rem;
  }
  .card-package-speed-avg {
    font-size: 0.625rem;
  }
  .symmetrical-speed-container {
        position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 1rem;
  }
  .symmetrical-speed-text {
    font-size: 0.625rem;
    font-weight: bold;
    text-align: center;
  }
  .symmetrical-speed-text:before {
    content: "";
    position: absolute;
    inset: 0px;
    background: linear-gradient(247deg, rgb(244, 17, 27) 0%, rgb(220, 16, 49) 41%, rgb(4, 2, 253) 100%) 0px 0px no-repeat padding-box padding-box transparent;
    opacity: 0.2;
  }
  .card-package-speed-icon {
    margin-right: 5px;
  }
`;
import { rem } from 'polished';

import { Cta } from '@components/Molecules';

export const StyledCTA = styled(Cta)`
  min-width: 100%;
  @media ${breakpoints.tablet} {
    min-width: 200px;
  }
`;
