import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { fromMarkdown, renderImage } from '@lib/utils';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';
import { Format, ImageDataT, UprnStatus } from '@lib/types';
import { Postcode } from '@components/Organisms/Postcode/Postcode';
import { useRouter } from 'next/router';
import usePostcodeContext from '@hooks/usePostcodeContext';
import { isExistingCustomerCheck, postcodeSubmitCallback } from '@lib/utils/uprn';
import Link from 'next/link';
import { Button, TrustBox } from '@components/Atoms';
import { ButtonType } from '@components/Atoms/Button/types';
import { mockTrustBoxData } from '@components/Atoms/TrustBox/__mockData__';

import { CtaT } from '../Cta/types';
import { Cta } from '../Cta';

interface PageHeroI {
  display: boolean;
  title: string;
  subtitle?: string;
  bodyContent?: string;
  cta?: CtaT;
  trustpilot?: boolean;
  displayPostCodeChecker?: boolean;
  postcodeLabel?: string;
  subtitleColour?: string;
  titleColour?: string;
  desktopImage: ImageDataT;
  mobileImage: ImageDataT;
}

const STYLED = {
  styledPageHero: styled.section`
    position: relative;
    overflow: hidden;
    position: relative;
    margin-top: 0;
    padding-top: 2rem;

    @media ${breakpoints.desktopLarge} {
      margin-top: 4rem;
    }
  `,
  pageHeroContent: styled.div`
    margin-right: auto;
    position: relative;
    @media ${breakpoints.tablet} {
      margin-top: 0;
    }
    @media ${breakpoints.smallDesktop} {
      max-width: 55%;
    }
  `,
  pageHeroTitleContent: styled.div`
    max-width: 50vw;
  `,
  pageHeading: styled.h1<{ colour: { color: string } }>`
    ${(props) =>
      props.colour.color === 'gradient'
        ? `
        background: -webkit-linear-gradient(
          135deg,
          hsla(357, 91%, 51%, 1) 0%,
          hsla(240, 100%, 50%, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `
        : `
        color: var(--color-${props.colour.color});
      `}
    color: var(--color-${(props) => props.colour.color});
    font-size: ${rem(50)};
    font-weight: 400;
    margin-bottom: 0;
    @media ${breakpoints.smallDesktop} {
      font-size: ${rem(70)};
    }
    @media ${breakpoints.desktop} {
      font-size: ${rem(97)};
    }
  `,
  pageHeroSubTitle: styled.div<{ colour: { color: string } }>`
    margin-top: 1rem;
    & p {
      color: var(--color-${(props) => props.colour.color});
      font-family: var(--font-geomanist);
      font-size: ${rem(25)};
      line-height: 1.1;
      font-weight: 400;
      @media ${breakpoints.tablet} {
        font-size: ${rem(35)};
      }
      @media ${breakpoints.desktop} {
        font-size: ${rem(53)};
      }
    }
  `,
  pageBody: styled.div`
    & * {
      color: var(--color-black);
    }
    @media ${breakpoints.smallDesktop} {
      margin-top: 0;
    }
  `,
  mobileImage: styled.div`
    position: absolute;
    z-index: -1;
    right: -100px;

    @media ${breakpoints.tablet} {
      display: none;
    }
  `,
  heroImage: styled.div`
    display: none;
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: -130px;

    div {
      display: flex;
    }

    @media ${breakpoints.tablet} {
      display: block;
      img {
        object-position: top;
      }
    }

    @media ${breakpoints.smallDesktop} {
      height: 100%;
      max-height: 760px;
      & > div {
        height: 100% !important;
      }
      & span {
        width: 45vw !important;
        height: 100% !important;
        img {
          object-fit: contain;
          object-position: bottom;
        }
      }
    }

    @media ${breakpoints.desktop} {
      right: 0;
      bottom: 0;
    }
  `,
  postcode: styled.div`
    margin-top: 1rem;
    [class*='StyledPostcodeContainer'] {
      margin-top: 1rem;
    }
    h3 {
      font-size: ${rem(20)};
      color: var(--color-black);
      margin-top: 2rem;
      font-weight: 600;
      margin-bottom: -1.5rem;
    }
  `,
  trustpilot: styled.div`
    height: 0;
    width: 300px;
    margin-left: -1.75rem;
  `,
  eligibleHeading: styled.h3`
    font-size: ${rem(22)};
    color: var(--color-primary);
    margin-top: 2rem;
    font-weight: 500;
    max-width: 25rem;
    line-height: 1.1;
    @media ${breakpoints.tablet} {
      max-width: 24rem;
      font-size: ${rem(25)};
    }
  `,
  eligibleBody: styled.p`
    font-size: ${rem(16)};
    color: black;
    margin-bottom: 1rem;
    font-weight: 300;
  `,
  eligibleButton: styled.div`
    margin-top: 2rem;
    margin-bottom: 3rem;
  `,
};

export const PageHero: FC<PageHeroI> = ({
  display,
  title,
  subtitle,
  bodyContent,
  cta,
  trustpilot,
  displayPostCodeChecker,
  postcodeLabel,
  subtitleColour,
  titleColour,
  desktopImage,
  mobileImage,
}) => {
  const router = useRouter();
  const { isCurrentPostcodeEligible, setHasConfirmedAddress, setPostcodeItem, clearPostcode } =
    usePostcodeContext();

  const handlePostcodeSubmit = useCallback(
    (data) => {
      if (isExistingCustomerCheck(data.coverage, data.oss_type)) {
        return router.push('/error/existing-customer');
      }
      postcodeSubmitCallback({
        data,
        ineligibleCallback: () => {
          return router.push('/service-unavailable');
        },
        successCallback: () => {
          setPostcodeItem(data);
          setHasConfirmedAddress(true);
        },
      });
    },
    [router, setHasConfirmedAddress, setPostcodeItem, clearPostcode],
  );

  if (!display) return null;

  return (
    <STYLED.styledPageHero className="slice">
      <div className="flex-col slice__inner">
        <STYLED.pageHeroContent>
          <STYLED.pageHeroTitleContent>
            <STYLED.pageHeading
              colour={{ color: titleColour ? titleColour : 'primary' }}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            {subtitle && (
              <STYLED.pageHeroSubTitle
                colour={{ color: subtitleColour ? subtitleColour : 'black' }}
              >
                {fromMarkdown({ text: subtitle, isHeading: false })}
              </STYLED.pageHeroSubTitle>
            )}
            {bodyContent && (
              <STYLED.pageBody>
                <div>{fromMarkdown({ text: bodyContent, isHeading: false })}</div>
              </STYLED.pageBody>
            )}
          </STYLED.pageHeroTitleContent>
          {cta && <Cta {...cta} />}
          {displayPostCodeChecker && (
            <STYLED.postcode>
              {!isCurrentPostcodeEligible && (
                <>
                  <h3>{postcodeLabel}</h3>
                  <Postcode
                    showTitle={false}
                    inline={true}
                    onSubmit={handlePostcodeSubmit}
                    placeholder=""
                    shouldRedirect={false}
                  />
                </>
              )}
            </STYLED.postcode>
          )}
          {displayPostCodeChecker && isCurrentPostcodeEligible && (
            <>
              <STYLED.eligibleHeading>
                Congrats! HeyBroadband is in your area
              </STYLED.eligibleHeading>
              <STYLED.eligibleBody>Learn more about our page</STYLED.eligibleBody>
              <STYLED.eligibleBody>
                <Link href="#whats-included" passHref>
                  <Button buttonType={ButtonType.PRIMARY}>
                    <a href="#whats-included">What&apos;s in the page?</a>
                  </Button>
                </Link>
              </STYLED.eligibleBody>
            </>
          )}
          {trustpilot && (
            <STYLED.trustpilot>
              <TrustBox data={mockTrustBoxData} />
            </STYLED.trustpilot>
          )}
        </STYLED.pageHeroContent>
        <STYLED.mobileImage>
          {renderImage({
            className: 'lg:h-full',
            alternativeText: mobileImage.alternativeText,
            formats: mobileImage.formats,
            format: Format.SMALL,
          })}
        </STYLED.mobileImage>
        <STYLED.heroImage>
          {renderImage({
            className: 'lg:h-full',
            alternativeText: desktopImage.alternativeText,
            formats: desktopImage.formats,
            format: Format.LARGE,
          })}
        </STYLED.heroImage>
      </div>
    </STYLED.styledPageHero>
  );
};
