import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { renderImage } from '@lib/utils';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';
import { Format, ImageDataT } from '@lib/types';

import snow1 from '@public/Snow1.png';
import snow2 from '@public/Snow2.png';
import snow3 from '@public/Snow3.png';
import snow4 from '@public/Snow4.png';
import Image from 'next/image';
import { Button } from '@components/Atoms';
import { ButtonType } from '@components/Atoms/Button/types';
import usePersistState from '@hooks/usePersistState';

interface ChooseCharityT {
  display: boolean;
  title: string;
  subtitle: string;
  charityCards: any;
  bannerImage: ImageDataT;
  peepingBennyImage: ImageDataT;
}

interface CharityCardProps {
  char: {
    charityDescription: string;
    logo: ImageDataT | null;
    charity: string;
  };
  index: number;
  text?: string;
  setSelectedCharity: (charity: string) => void;
  selectedCharity: string;
}

const STYLED = {
  wrapper: styled.section`
    position: relative;
    min-height: 70vh;
  `,
  bannerImage: styled.div<{ banner }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3rem;
    width: 100%;
    background-color: var(--color-blue);
    background-image: url(${(props) => props.banner});
    background-size: cover;
    background-position: top
    background-repeat: no-repeat;
    z-index: -1;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 3rem;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }

    @media ${breakpoints.tablet} {
      height: ${rem(142)};
    }
  `,
  peepingBenny: styled.div`
    position: absolute;
    bottom: -1.6rem;
    right: 10%;
    z-index: 1;
    width: 245px;
    height: 110px;
  `,
  header: styled.header`
    margin-bottom: 2rem;

    @media ${breakpoints.tablet} {
      padding-top: 5rem;
    }

    h1 {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 0.5rem;
      background: -webkit-linear-gradient(
        135deg,
        hsla(357, 91%, 51%, 1) 0%,
        hsla(240, 100%, 50%, 1) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: ${rem(45)};
      margin-bottom: 0;

      @media ${breakpoints.tablet} {
        font-size: ${rem(75)};
      }

      @media ${breakpoints.desktop} {
        font-size: ${rem(90)};
      }

      @media ${breakpoints.desktopXL} {
        font-size: ${rem(110)};
      }
    }
    p {
      font-size: 1.25rem;
      color: var(--color-black);
      line-height: 1.6;
      font-size: ${rem(25)};
      font-family: var(--font-geomanist);

      @media ${breakpoints.tablet} {
        font-size: ${rem(30)};
      }

      @media ${breakpoints.desktop} {
        font-size: ${rem(35)};
      }
    }

    h1,
    p {
      text-align: center;
    }
  `,
  cards: styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${rem(40)};
    margin-bottom: 2rem;

    @media ${breakpoints.tablet} {
      grid-template-columns: repeat(2, 300px);
    }

    @media ${breakpoints.desktopXL} {
      grid-template-columns: repeat(4, 300px);
    }
  `,
  card: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    & > p {
      text-align: center;
      width: 80%;
    }
  `,
  cardWrapper: styled.div`
    position: relative;
    width: fit-content;
  `,
  cardshadow: styled.div`
    position: absolute;
    inset: 0;
    opacity: 0.3;
    filter: blur(15px);
    transform: translate3d(0px, 15px, -1px);
    border-radius: inherit;
    pointer-events: none;
    background: linear-gradient(99deg, rgb(244, 17, 27) 0%, rgb(4, 2, 253) 100%) 0% 0% no-repeat
      padding-box padding-box transparent;
    transition: opacity 0.3s;

    &.active {
      opacity: 0.8;
    }
  `,
  cardcontent: styled.button`
    width: 290px;
    height: 200px;
    background-color: var(--color-blue);
    border-radius: 10px;
    padding: 1.5rem;
    padding-top: 5rem;
    display: flex;
    transition: transform 0.3s ease 0s, background-color 0.3s ease 0s;
    position: relative;
    z-index: 1;
    boreder: 1px solid;
    border: 1px solid #bdd6ff;

    &:hover {
      transform: translateY(-10px);
      background-color: #ededf7;
      -webkit-tap-highlight-color: transparent;
    }

    &.active {
      background-color: var(--color-white);
      transform: translateY(-10px);

      &:before {
        content: '';
        border-radius: 10px;
        border: 3px solid transparent;
        background: linear-gradient(99deg, rgb(244, 17, 27) 0%, rgb(4, 2, 253) 100%) border-box;
        mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0);
        mask-composite: exclude;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    & .snow {
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      width: 330px;
      height: 150px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    p {
      background: -webkit-linear-gradient(
        135deg,
        hsla(240, 100%, 50%, 1) 0%,
        hsla(357, 91%, 51%, 1) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      font-family: var(--font-geomanist);
      font-size: 1.75rem;
      position: relative;
      width: 50%;
      margin: 0 auto;
      margin-top: -1rem;
      line-height: 1.2;
    }
  `,
  footer: styled.footer`
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    input {
      box-shadow: rgba(73, 134, 216, 0.47) 0px 2px 26px;
      margin-top: 1rem;
      border-radius: 5px;
      width: 300px;
      padding: 1rem;
      border-radius: 5px;
      border: 1px solid lightgray;

      &:focus,
      &:active,
      &:focus-visible {
        outline-color: var(--color-primary);
      }
    }
  `,
  submission: styled.div`
    text-align: center;
    margin-top: 4rem;

    @media ${breakpoints.tablet} {
      width: 70%;
    }

    h1 {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 0.5rem;
      background: -webkit-linear-gradient(
        135deg,
        hsla(357, 91%, 51%, 1) 0%,
        hsla(240, 100%, 50%, 1) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: ${rem(45)};
      margin-bottom: 2rem;

      @media ${breakpoints.tablet} {
        font-size: ${rem(75)};
      }

      @media ${breakpoints.desktop} {
        font-size: ${rem(90)};
      }

      @media ${breakpoints.desktopXL} {
        font-size: ${rem(110)};
      }
    }

    p {
      margin: 0 auto;
      width: 80%;
    }
  `,
};

const CharityCard: FC<CharityCardProps> = ({
  char,
  index,
  text,
  setSelectedCharity,
  selectedCharity,
}) => {
  const { charityDescription, logo, charity } = char;
  const snowImages = [snow1, snow2, snow3, snow4];
  const snow = snowImages[index % 4];

  return (
    <STYLED.card>
      <STYLED.cardWrapper>
        <STYLED.cardshadow></STYLED.cardshadow>
        <STYLED.cardcontent
          onClick={() => setSelectedCharity(charity)}
          className={selectedCharity === charity ? 'active' : ''}
        >
          <div className="snow">
            <Image src={snow.src} alt="snow" layout="fill" />
          </div>
          {logo && (
            <div className="charity-card__image">
              {renderImage({
                alternativeText: logo.alternativeText,
                formats: logo.formats,
                format: Format.SMALL,
              })}
            </div>
          )}
          {text && <p>{text}</p>}
        </STYLED.cardcontent>
      </STYLED.cardWrapper>
      <p>{charityDescription}</p>
    </STYLED.card>
  );
};

export const ChooseCharity: FC<ChooseCharityT> = ({
  display,
  title,
  subtitle,
  charityCards,
  bannerImage,
  peepingBennyImage,
}) => {
  const [selectedCharity, setSelectedCharity] = useState('');
  const [customCharity, setCustomCharity] = useState('');
  const [submissionSent, setSubmissionSent] = useState(false);
  const [completed, setCompleted] = usePersistState(
    false,
    'donateChoiceCompleted',
    1000 * 60 * 60 * 24 * 7,
  );

  const handleSubmit = async () => {
    let charityToSubmit = selectedCharity;

    if (selectedCharity === 'Other') {
      charityToSubmit = customCharity;
      setSelectedCharity(customCharity);
    }

    try {
      const response = await fetch('/api/donation-tracker', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ charity: charityToSubmit }), // Use the local variable
      });

      if (!response.ok) {
        console.error('Error updating donation tracker');
        setSubmissionSent(true);
        return;
      }

      console.log('Donation tracker updated successfully');
      setSubmissionSent(true);
      setCompleted(true);
    } catch (error) {
      console.error('Error updating donation tracker:', error);
      setSubmissionSent(true);
    }
  };

  if (!display) return null;
  return (
    <STYLED.wrapper className="slice">
      {bannerImage && <STYLED.bannerImage banner={bannerImage.url} />}
      {peepingBennyImage && (
        <STYLED.peepingBenny>
          {renderImage({
            alternativeText: peepingBennyImage.alternativeText,
            formats: peepingBennyImage.formats,
            format: Format.SMALL,
          })}
        </STYLED.peepingBenny>
      )}
      <div className="flex-col slice__inner">
        {!submissionSent && !completed && (
          <>
            {title && (
              <STYLED.header>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: subtitle,
                  }}
                />
              </STYLED.header>
            )}
            {charityCards && (
              <STYLED.cards>
                {charityCards.map((charity, index) => (
                  <CharityCard
                    key={index}
                    char={charity}
                    index={index}
                    setSelectedCharity={setSelectedCharity}
                    selectedCharity={selectedCharity}
                  />
                ))}
                <CharityCard
                  char={{
                    charityDescription:
                      "Your opinion matters, tell us about a charity that isn't on the list",
                    logo: null,
                    charity: 'Other',
                  }}
                  text="Suggest another charity"
                  index={3}
                  setSelectedCharity={setSelectedCharity}
                  selectedCharity={selectedCharity}
                />
              </STYLED.cards>
            )}
            <STYLED.footer>
              {selectedCharity === 'Other' && (
                <input
                  type="text"
                  name="otherCharity"
                  value={customCharity}
                  placeholder="I want HB! to donate to..."
                  onChange={(e) => setCustomCharity(e.target.value)}
                />
              )}
              <Button
                disabled={!!!selectedCharity || (selectedCharity === 'Other' && !customCharity)}
                buttonType={ButtonType.PRIMARY}
                type="button"
                onClick={handleSubmit}
              >
                Select your chosen charity
              </Button>
            </STYLED.footer>
          </>
        )}
        {submissionSent && completed && (
          <STYLED.submission>
            <h1>Thank you for choosing a charity!</h1>
            <p>
              Once all votes have been collected, we will send an email so that you can see how much
              your chosen charity has recieved
            </p>
          </STYLED.submission>
        )}
        {completed && !submissionSent && (
          <STYLED.submission>
            <h1>Thanks! You have already chosen a charity!</h1>
          </STYLED.submission>
        )}
      </div>
    </STYLED.wrapper>
  );
};
